.containerMain {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('https://nws-dealer-portal.s3.amazonaws.com/styles/img/loginBg.jpg') no-repeat center center fixed;
    background-size: cover;
    overflow: hidden;
  }
  
  .bg-danger {
    background-color: #ff4961;
    color: white;
    text-align: center;
  }
  
  .bg-success {
    background-color: #dff0d8;
    color: #3c763d;
  }
  
  .bg-info {
    background-color: #d9edf7;
    color: #31708f;
  }
  
  .showHideBtn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  
  .forgetLink {
    display: inline-block;
    width: max-content;
    margin-left: auto;
    margin-top: 0;
    font-size: 12px;
    color: #669A41;
    text-align: right;
    cursor: pointer;
  }
  
  .submitBtn {
    margin-top: 18px;
    background-color: #669A41;
  }
  
  .container {
    background-color: #FFF;
    border-radius: 10px;
  }