 .small-popup {
    padding: 10px; /* Ajusta el padding interno */
    /* height: 200px; */
  }
  
  .small-title {
    font-size: 20px; /* Ajusta el tamaño del título */
  }
  
  .small-content {
    font-size: 16px; /* Ajusta el tamaño del texto */
  }
  
  .small-confirm-button {
    font-size: 16px; /* Ajusta el tamaño del botón */
    background-color:brown
  }

  .small-cancel-button {
    font-size: 16px; /* Ajusta el tamaño del botón */
    background-color:#928f8f
  }

  .custom-icon {
    margin-top: 10px; /* Ajusta el valor según lo necesites */
  }
